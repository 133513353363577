module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.codelinaro.org"},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://df67765ff240452ca30e03480431d0f0@o448810.ingest.sentry.io/5339411","environment":"production","release":"3d94db752404cd7d984e2afd5faf3ac24aeb7d84"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
